.container {
  width: 65rem;
  max-width: 90rem;
  margin-top: 40px;
}

.maxContainer {
  width: 80%;
  max-width: 100%;
  margin-top: 40px;
}
